@import url(https://fonts.googleapis.com/css2?family=Inria+Sans&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400&display=swap);
body {
    margin: 0;
    color: #333;
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Inria Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.main {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
}

.div-div {
    margin: auto;
    width: 50px;
    height: 3px;
    background-color: #35B4F1;
}

a {
    color: #35B4F1;
}

#content {
    min-height: 55vh;
}

.navbar {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 1em 2em;
    background-color: #73a977;
    overflow: hidden;
}

.navbar-brand {
    order: 1;
}

.navbar-brand-link {
    display: inline-block;
    width: 71px;
    height: 60px;
}

.navbar-nav {
    order: 2;
    flex: 1 0 auto;
    margin: 0;
    padding: 0;
    text-align: right;
}

.navbar-nav-item {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: .5em 0;
    display: inline-block;
}

.navbar-nav-link {
    display: inline-block;
    padding: .5em .6em;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    transition: linear .1s;
}

.navbar-nav-link, .navbar-nav-link:visited {
    color: #fff;
    opacity: .8;
}

.navbar-nav-link:hover {
    opacity: 1;
    text-shadow: 1px 1px 1px rgba(0,0,0,.4);
}

.navbar-nav-link.current {
    opacity: 1;
    color: #333;
}

footer {
    color: #fff;
    padding: 2em;
    text-align: center;
    background-color: #222;
}

.footer-h1 {
    font-weight: normal;
}

.footer-smallprint {
    font-size: .8em;
}

#download-cta {
    padding: 2em;
    text-align: center;
}

#download-ram {
    padding: 2em;
    text-align: center;
    background-color: #eee;
}

.download-cta-h1 {
    font-size: 2em;
    margin: 0.67em;
    -webkit-margin-before: 0.67em;
            margin-block-start: 0.67em;
    -webkit-margin-after: 0.67em;
            margin-block-end: 0.67em;
    font-weight: normal;
}

.download-cta-options {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center
}

.download-cta-option {
    margin: 2em;
}

.download-cta-option-desc p {
    margin: 0;
    padding: 0;
    color: #666;
}

.download-cta-option-img img {
    border-radius: 100%;
}

.download-cta-option-link, .download-ram-btn {
    border: none;
    cursor: pointer;
    display: inline-block;
    color: #fff;
    text-decoration: none;
    border-radius: 10em;
    padding: 1em 2em;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #35B4F1;
    margin-top: 2em;
}

.download-ram-btn {
    font-size: .8em;
}

.download-ram-btn:disabled {
    opacity: .3;
    cursor: default;
}

.progress-bar {
    max-width: 350px;
    padding: 2px;
    background-color: rgba(0,0,0,.1);
    border-radius: 5px;
    margin: 3em auto 2em auto;
}

.progress {
    padding: .8em 0;
    font-size: .8em;
    border-radius: 3px;
    background-color: #35B4F1;
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 1px 0 rgba(0,0,0,.4);
}

#home-header {
    text-align: center;
    padding: 1em 2em;
    background-color: #eee;
}

.home-header-h1 {
    font-weight: normal;
    font-size: 3em;
    padding-bottom: 0;
    margin-bottom: 0;
}

.home-header-h3 {
    padding-top: 0;
    margin-top: 0;
}

.home-header-banner-img {
    width: 100%;
    height: 100%;
    max-width: 740px;
    max-height: 420px;
}

.home-download-h1 {
    color: #35B4F1;
}

#about {
    padding: 2em;
    margin: auto;
    max-width: 720px;
}

#about h1 {
    text-align: center;
    font-weight: normal;
}

