#download-cta {
    padding: 2em;
    text-align: center;
}

#download-ram {
    padding: 2em;
    text-align: center;
    background-color: #eee;
}

.download-cta-h1 {
    font-size: 2em;
    margin: 0.67em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    font-weight: normal;
}

.download-cta-options {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center
}

.download-cta-option {
    margin: 2em;
}

.download-cta-option-desc p {
    margin: 0;
    padding: 0;
    color: #666;
}

.download-cta-option-img img {
    border-radius: 100%;
}

.download-cta-option-link, .download-ram-btn {
    border: none;
    cursor: pointer;
    display: inline-block;
    color: #fff;
    text-decoration: none;
    border-radius: 10em;
    padding: 1em 2em;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #35B4F1;
    margin-top: 2em;
}

.download-ram-btn {
    font-size: .8em;
}

.download-ram-btn:disabled {
    opacity: .3;
    cursor: default;
}

.progress-bar {
    max-width: 350px;
    padding: 2px;
    background-color: rgba(0,0,0,.1);
    border-radius: 5px;
    margin: 3em auto 2em auto;
}

.progress {
    padding: .8em 0;
    font-size: .8em;
    border-radius: 3px;
    background-color: #35B4F1;
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 1px 0 rgba(0,0,0,.4);
}
