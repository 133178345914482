@import url('https://fonts.googleapis.com/css2?family=Inria+Sans&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400&display=swap');

body {
    margin: 0;
    color: #333;
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Inria Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.main {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
}

.div-div {
    margin: auto;
    width: 50px;
    height: 3px;
    background-color: #35B4F1;
}

a {
    color: #35B4F1;
}

#content {
    min-height: 55vh;
}
