#home-header {
    text-align: center;
    padding: 1em 2em;
    background-color: #eee;
}

.home-header-h1 {
    font-weight: normal;
    font-size: 3em;
    padding-bottom: 0;
    margin-bottom: 0;
}

.home-header-h3 {
    padding-top: 0;
    margin-top: 0;
}

.home-header-banner-img {
    width: 100%;
    height: 100%;
    max-width: 740px;
    max-height: 420px;
}

.home-download-h1 {
    color: #35B4F1;
}
